body {
  margin: 0;
  font-family: 'Droid Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2c3138;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  height: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

button {
  border-radius: 6px;
  border-color: rgb(108, 139, 216);
  border-style: solid;
  border-width: 3px;
  padding: 15px 20px;
  color: rgb(24, 39, 76);
  font-weight: 800;
  font-size: 20px;
  &.mainButton {
    width: 80%;
    margin: 5px;
    &:disabled {
      color: white;
      padding: 5px 20px;
    }
  }
  &.backButton {
    padding: 5px 10px;
    position: absolute;
    top: 10px;
    left: 10%;
  }
}

.videoBox {
  width: 80%;
  border: 3px solid white;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
}

.videoBox video {
  width: 100%;
  display: block;
}

.videoBox:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.scanSuccess:before {
  background: rgba(87, 221, 54, 0.506);
}

.scanFail:before {
  background: rgba(221, 54, 54, 0.506);
}

.scanning:before {
  background: rgba(246, 246, 162, 0.506);
}

table {
  width: 80%;
  text-align: left;
  background-color: white;
  border: 3px solid rgb(108, 139, 216);
  border-radius: 6px;
  color: black;
  margin: 5px 0 0 0;
  table-layout: fixed;
}
th {
  width: 40%;
}

td button{
  padding: 4px 20px;
  float: right;
  border-color: rgb(108, 108, 108);
  &.confirm {
    margin: 5px auto;
    &.red{
      border-color: rgb(255, 0, 0);
      background: rgba(212, 33, 33, 0.95);
      color: rgb(255, 255, 255);
    }
    &.green{
      border-color: rgb(11, 113, 11);
      background: rgba(0, 255, 0, 1);
      color: rgb(58, 72, 50);
    }
  }
}
td input{
  float:right;
  padding: 4px 4px;
  border: 3px solid rgb(108, 108, 108);
  border-radius: 6px;
}

.warningBlock{
  background: rgba(242, 150, 51, 0.95);
  border: 3px solid rgb(255, 111, 0);
  padding: 5px;
  border-radius: 6px;
}